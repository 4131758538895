export default {
  home: {
    url: '/',
    label: 'home',
  },
  about: {
    url: '/about',
    label: 'about',
  },
  culture: {
    url: '/culture',
    label: 'culture'
  },
  career: {
    url: '/careers',
    label: 'careers',
    api: {
      careerData: {
        url: '/dtlweb/api/career_data',
        label: 'career page api',
      }
    },
    onlineApplication: {
      url: "/dtlweb/careers",
      label: "Online Application",
    }
  },
  // recruitmentcampaign: {
  //   url: '/dtlweb/campaign/2025recruitment',
  //  label: '2025 recruitment',
  // },
  contact: {
    url: '/contact',
    label: 'contact',
  },
  terms_of_use: {
    url: '/terms_of_use',
    label: 'Term of Use',
  },
}
